import { ReactElement } from 'react';
import Image from 'next/image';
import { CDN2 } from '@/helpers/api';

export default function CruisePorts(): ReactElement {
  return (
    <>
      <div className="w-full mb-12">
        <div className="h-full flex flex-col items-center justify-center space-y-8">
          <p className="text-2xl md:text-3xl">Cruise Ports</p>
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="flex flex-row flex-wrap">
          <div className="w-full md:w-2/3 h-64 mb-4 px-2">
            <a
              className="relative flex flex-col items-center justify-center w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover"
              href="parking/Miami-Port-MIA"
              title="Port of Miami"
            >
              <Image
                src={CDN2() + '/staticmyapp/port-of-miami.png'}
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                className="z-0"
                priority
                alt="Port of Miami"
              />
              <span className="block bg-white uppercase text-primary py-2 px-8 rounded-md z-10">
                PORT OF MIAMI
              </span>
            </a>
          </div>
          <div className="w-full md:w-1/3 h-64 mb-4 px-2">
            <a
              className="relative flex flex-col items-center justify-center w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover"
              href="parking/Port-Everglades-EVG"
              title="Port of Everglades"
            >
              <Image
                src={CDN2() + '/staticmyapp/port-of-everglades.png'}
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                className="z-0"
                priority
                alt="Port of Everglades"
              />
              <span className="block bg-white uppercase text-primary py-2 px-8 rounded-md z-10">
                PORT OF EVERGLADES
              </span>
            </a>
          </div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="w-full md:w-1/3 h-64 mb-4 px-2">
            <a
              className="relative flex flex-col items-center justify-center w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover"
              href="parking/Tampa-Port-TMP"
              title="Port of Tampa"
            >
              <Image
                src={CDN2() + '/staticmyapp/port-of-tampa.png'}
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                className="z-0"
                priority
                alt="Port of Tampa"
              />
              <span className="block bg-white uppercase text-primary py-2 px-8 rounded-md z-10">
                PORT OF TAMPA
              </span>
            </a>
          </div>
          <div className="w-full md:w-2/3 h-64 mb-4 px-2">
            <a
              className="relative flex flex-col items-center justify-center w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover"
              href="parking/Jacksonville-Port-JXP"
              title="Port of Jacksonville"
            >
              <Image
                src={CDN2() + '/staticmyapp/port-of-jacksonville.png'}
                fill
                style={{ objectFit: 'cover' }}
                quality={100}
                className="z-0"
                priority
                alt="Port of Jacksonville"
              />
              <span className="block bg-white uppercase text-primary py-2 px-8 rounded-md z-10">
                PORT OF JACKSONVILLE
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
